import React, { useState, useEffect, useRef } from 'react';
import styles from './Chat.module.css';  // Assuming you use CSS modules, change if using regular CSS

function Chat({persona}) {
  const [message, setMessage] = useState('');
  const [responses, setResponses] = useState([]);
  const ws = useRef(null);
  const messagesEndRef = useRef(null);

  const apiUrl =  process.env.REACT_APP_API_URL



//  console.log(persona)

  useEffect(() => {
  //  ws.current = new WebSocket('ws://localhost:4000'); // Change URL as needed

  ws.current = new WebSocket(`${apiUrl}`);
//   ws.current = new WebSocket(`${apiUrl}`);

    ws.current.onmessage = (event) => {
      const data = event.data;
      const parts = data.split('\n').reduce((acc, part) => {
        if (acc.length > 0) acc.push(<br key={acc.length} />);
        acc.push(part);
        return acc;
      }, []);
      setResponses(prevResponses => prevResponses.map((resp, idx, arr) => {
        if (idx === arr.length - 1) { // Only update the last (most recent) response
          return { ...resp, content: [...resp.content, ...parts] };
        }
        return resp;
      }));
    };
    ws.current.onclose = () => console.log("WebSocket connection closed");

    return () => {
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [responses]);

  const sendMessage = () => {
    if (message.trim()) {
      ws.current.send(JSON.stringify({ content: message, persona: persona })); // Include both message and persona
      setResponses(prevResponses => [...prevResponses, { id: prevResponses.length, content: [] }]);
      setMessage('');
    }
  };

  return (
    <div className={styles.chat}>
      <div className={styles.messages}>
        {responses.map((response) => (
          <div key={response.id} className={styles.response}>
            {response.content.map((part, index) => typeof part === 'string' ? <span key={index}>{part}</span> : part)}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className={styles['input-area']}>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent the default Enter key behavior
            sendMessage();
          }
        }}
        placeholder="Enter your message"
        />
        <button onClick={sendMessage}>Ask</button>
      </div>
    </div>
  );
}

export default Chat;
