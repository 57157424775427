import React, { useState } from 'react';
import './App.css';
import Chat from './Chat';
import Trait from './Trait';

function App() {
  const [persona, setPersona] = useState('');

  const handleTextChange = (text) => {
    setPersona(text);
  
  };

  return (
    <div className="app">
      <div className="column left">
        <Trait onTextChange={handleTextChange} />
      </div>
      <div className="column center">
        <Chat persona={persona} />
      </div>
      <div className="column right"></div> {/* Right column */}
    </div>
  );
}

export default App;
